
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    title: VueTypes.string,
    uri: VueTypes.string,
    visual: VueTypes.objectOf({
      kind: VueTypes.string,
    }),
  },
};
