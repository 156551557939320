
import VueTypes from 'vue-types';

import themesQuery from '@/gql/queries/sections/themes.gql';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
  },
  data() {
    return {
      themes: null,
    };
  },
  async fetch() {
    const { themes } = await this.$cms.query({
      query: themesQuery,
      variables: {
        site: this.$site,
      },
    });
    this.themes = themes;
  },
};
