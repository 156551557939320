import { render, staticRenderFns } from "./Themes.vue?vue&type=template&id=6658bbcc"
import script from "./Themes.vue?vue&type=script&lang=js"
export * from "./Themes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VisualCard: require('/workspace/app/components/cards/VisualCard.vue').default,Container: require('/workspace/app/components/layout/Container.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})
